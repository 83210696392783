import React from "react";
import SVGIcon from "./SVGIcon";

const FacebookIcon = ({ color, bg, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={bg}
      fillRule="evenodd"
      d="M19 38a19 19 0 100-38 19 19 0 000 38z"
    />
    <path
      fill={color}
      d="M17.1434 27.8669V19.9354H14.5674V16.7835H17.1434V14.3002C17.1434 11.6021 18.7421 10.1335 21.0763 10.1335C22.1947 10.1335 23.1553 10.2201 23.434 10.2582V13.0776H21.8152C20.5457 13.0776 20.3005 13.701 20.3005 14.6119V16.7835H23.1654L22.7724 19.9354H20.3005V27.8669"
    />
  </SVGIcon>
);

FacebookIcon.defaultProps = {
  color: "#51677B",
  bg: "#DAE1E7",
  width: "38",
  height: "38",
  viewBox: "0 0 38 38"
};

export default FacebookIcon;
