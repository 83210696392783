/**
 * All the credit to:
 * https://daneden.github.io/animate.css/
 */
import { css, keyframes } from "@emotion/core";

export const animated = css`
  animation-duration: 1s;
  animation-fill-mode: both;
`;

// export const infinite = css`
//   animation-iteration-count: infinite;
// `;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -25%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
