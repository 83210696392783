import React from "react";

export const Chevron = ({ color, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 28" {...props}>
    <path
      d="M3.17 0L0 3.13l10.3 10.2L0 23.53l3.17 3.14 13.5-13.34z"
      fill={color}
    />
  </svg>
);

Chevron.defaultProps = {
  width: 17,
  height: 28,
  color: "currentColor"
};

export default Chevron;
