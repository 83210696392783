import React from "react";

import { Helmet } from "react-helmet";
import { css, Global } from "@emotion/core";

import "../css/preflight.css";
// import "typeface-inter";

import tw from "tailwind.macro";
import favicon from "../images/favicon.ico";
import SEOMeta from "./SEOMeta";

const facebookDID = process.env.GATSBY_FACEBOOK_DOMAIN_ID;
const styles = {
  pgBG: tw`relative z-30`,
};

const Layout = ({ className, children, includeMetaTags, meta }) => (
  <div>
    <Global
      styles={css`
        html {
          font-family: "Inter", "sans-serif";
        }
      `}
    />
    <Helmet>
      <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      />
      {facebookDID && <meta name="facebook-domain-verification" content={facebookDID} />}
    </Helmet>
    {includeMetaTags && <SEOMeta {...meta} />}
    <div className={className} css={styles.pgBG}>
      {children}
    </div>
  </div>
);

Layout.defaultProps = {
  includeMetaTags: true,
};

export default Layout;
