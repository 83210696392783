const useSiteMetadata = () => {
  const siteUrl =
    (process.env.CONTEXT !== "production" && process.env.DEPLOY_PRIME_URL) ||
    "https://masterclass.ted.com";

  return {
    siteUrl,
  };
};

export default useSiteMetadata;
