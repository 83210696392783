export const sm = `min-width: 576px`;
export const md = `min-width: 768px`;
export const lg = `min-width: 992px`;
export const xl = `min-width: 1200px`;
export const xxl = `min-width: 1440px`;

export const mdMax = `max-width: 991px`;
export const lgMax = `max-width: 1199px`;

export const blogMd = `min-width: 600px`;
export const blogLg = `min-width: 894px`;
