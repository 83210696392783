import React from "react";

import Btn from "../Btn";
import { CloseX } from "../icons";
import NavLink from "../NavLink";
import { PoseGroup } from "react-pose";
import { PoseMenu, PoseSmoke } from "../PoseDefinitions";

import { depth2 } from "../../styles/utilities.styles";
import { css } from "@emotion/core";
import tw from "tailwind.macro";

import { useWindowSize } from "react-use";

const styles = {
  wrap: tw`relative h-screen flex items-strech`,
  backDrop: tw`w-1/3 h-screen`,
  menuBG: css`
    ${tw`w-2/3 h-screen bg-white z-30`};
    ${depth2};
  `,
  menu: tw`flex flex-col p-8 text-right items-end w-full`,
  link: tw`no-underline text-black py-4 w-full text-lg`,
  adminLink: tw`py-4 border-t`,
  closeIcon: tw`p-4 mb-4 -mt-4 -mr-4 cursor-pointer`,
  poseMenu: tw`pin fixed z-40`,
  poseSmoke: tw`pin fixed bg-near-black z-30`,
  webApp: css`
    padding: 9px 16px;
    font-size: 16px;
    line-height: ${24 / 16};
    letter-spacing: -0.4px;
    margin-left: 16px;
  `,
};

const Menu = ({ links, toggleMenu }) => {
  const { width } = useWindowSize();

  return (
    <div css={styles.wrap}>
      <div css={styles.backDrop} onClick={() => toggleMenu()} />
      <div css={styles.menuBG}>
        <div css={styles.menu}>
          <button css={styles.closeIcon} onClick={() => toggleMenu()}>
            <CloseX color="#111111" />
          </button>
          {links.map((link, idx) => (
            <NavLink
              {...link}
              css={styles.link}
              key={idx}
              data-track-action="Menu Link"
            />
          ))}
          {width >= 600 && (
            <Btn
              css={styles.webApp}
              data-track-action="Menu Get Started"
              href="https://masterclass.ted.com/web/"
              label="Get Started"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Menu.defaultProps = {
  links: [],
};

export const MenuAnimated = ({ isMenuOpen, links, toggleMenu }) => (
  <PoseGroup flipMove={false}>
    {isMenuOpen && [
      <PoseMenu key="1" css={styles.poseMenu}>
        <Menu links={links} toggleMenu={toggleMenu} />
      </PoseMenu>,
      <PoseSmoke key="2" css={styles.poseSmoke} />,
    ]}
  </PoseGroup>
);

export default Menu;
