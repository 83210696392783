import React from "react";
import { Helmet } from "react-helmet";

import { useLocation } from "@reach/router";
import useSiteMetadata from "../hooks/useSiteMetadata";

import ogImage from "../images/MC_YT_Thumb1.jpeg";
// import ogImage from "../images/ted-masterclass.jpg";

const lang = "en";

const siteMeta = {
  title: "TED Masterclass: TED's Official Public Speaking Course",
  description:
    "TED Masterclass -- TED’s official public speaking course -- will teach you how to identify, develop and share your best ideas with the world.",
  ogImage: {
    src: ogImage,
    height: 1200,
    width: 1200,
  },
};

const SEOMeta = ({ meta: pageMeta, ...props }) => {
  const location = useLocation();
  const { siteUrl } = useSiteMetadata();

  const { pathname } = location;
  const { ogImage: image, title, description, ...rest } = {
    ...siteMeta,
    ...pageMeta,
  };

  const metaProps = {
    "og:type": "website",
    "fb:app_id": "201021956610141",
    title: title,
    "og:title": title,
    ...((description && {
      description,
      "og:description": description,
    }) ||
      {}),
    "og:url": (pathname && `${siteUrl}${pathname}`) || siteUrl,
    ...((image && {
      "og:image": `${siteUrl}${image.src}`,
      "twitter:card": "summary_large_image",
      "og:image:width": image.height,
      "og:image:height": image.width,
    }) ||
      {}),
    ...rest,
  };

  const meta = Object.keys(metaProps)
    .reduce((tags, k) => {
      const tagType =
        k === "name" || k === "description" || k.indexOf("twitter:") !== -1
          ? "name"
          : "property";
      const tag = {
        [tagType]: k,
        content: metaProps[k],
      };
      return [...tags, tag];
    }, [])
    .filter(({ content }) => !!content);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={meta}
      {...props}
    />
  );
};

SEOMeta.defaultProps = {
  meta: {},
};

export default SEOMeta;
