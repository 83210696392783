import React from "react";

export const OpenCircleCheck = ({ color, ...props }) => (
  <svg viewBox="0 0 28 26" {...props}>
    <path
      fill="#FF5041"
      d="M13 0c2.5 0 4.8.7 6.8 2h.2l-1 1.5a11.1 11.1 0 00-5.5-1.6H13a11.2 11.2 0 1011.2 10.4l1.6-1.3v.1l.2 1.5v.5A13 13 0 1113 0zm12.7 1.7a.5.5 0 01.7 0l1.3 1a.5.5 0 010 .8L14.1 17.8a.5.5 0 01-.5.1.5.5 0 01-.2 0l-7-6a.5.5 0 010-.7l1.2-1.3a.5.5 0 01.7 0l5.2 4.5z"
    />
  </svg>
);

OpenCircleCheck.defaultProps = {
  width: 28,
  height: 26,
  color: "#FF5041"
};

export default OpenCircleCheck;
