export const tedRed = "#E62B1E";
export const watermelon = "#FF5041";
export const white = "#FFFFFF";
export const bgDarkBlue = "#21292F";
export const bgLightBlue = "#F8FAFC";
export const black = "#000000";

export const textPrimary = "#1D1D1D";
export const textSecondary = "#606F7B";

export const gray1 = "#DAE1E7";
export const gray2 = "#51677B";

export const grayLight = gray1;
