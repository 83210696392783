import { css } from "@emotion/core";
import { inter } from "./font-weights";

export const Inter6 = css`
  font-weight: ${inter.bold};
  font-size: 58px;
  line-height: ${70 / 58};
  letter-spacing: -3.31429px;
`;

export const Inter5 = css`
  font-weight: ${inter.bold};
  font-size: 48px;
  line-height: ${52 / 48};
  letter-spacing: -1.8px;
`;

export const Inter4 = css`
  font-weight: ${inter.bold};
  font-size: 36px;
  line-height: ${44 / 36};
  letter-spacing: -1.2px;
`;

export const Inter3 = css`
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.2px;
`;

export const Inter3b = css`
  ${Inter3};
  font-weight: ${inter.semiBold};
`;

export const Inter2 = css`
  font-weight: ${inter.semiBold};
  font-size: 20px;
  line-height: ${30 / 20};
  letter-spacing: -0.2px;
`;

export const Inter2r = css`
  ${Inter2};
  font-weight: ${inter.regular};
`;

export const Inter1 = css`
  font-weight: ${inter.semiBold};
  font-size: 18px;
  line-height: ${22 / 18};
  letter-spacing: -0.2px;
`;

export const Inter1r = css`
  ${Inter1};
  font-weight: ${inter.regular};
`;

export const Inter01 = css`
  font-size: 16px;
  line-height: ${26 / 16};
  letter-spacing: -0.5px;
`;

export const Inter02 = css`
  font-size: 14px;
  line-height: ${22 / 14};
  letter-spacing: -0.35px;
  font-weight: ${inter.light};
`;

export const Inter02b = css`
  ${Inter02};
  font-weight: ${inter.bold};
`;
