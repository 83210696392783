import React from "react";

export const Hamburger = ({ color, ...props }) => (
  <svg viewBox="0 0 30 16" {...props}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M0 0h30v2H0zM0 7h30v2H0zM5 14h25v2H5z"
    />
  </svg>
);

Hamburger.defaultProps = {
  width: 30,
  height: 16,
  color: "#FFF"
};

export default Hamburger;
