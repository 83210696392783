import React from "react";

export const CloseX = ({ color, ...props }) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M10.48283 8.98528l7.48773 7.48774-1.49754 1.49754-7.48774-7.48773-7.48773 7.48773L0 16.47302l7.48773-7.48774L0 1.49755 1.49755 0l7.48773 7.48773L16.47302 0l1.49754 1.49755-7.48773 7.48773z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

CloseX.defaultProps = {
  width: 18,
  height: 18,
  color: "#FFF"
};

export default CloseX;
