import React from "react";

export const BlogMarkIcon = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 45 45"
    {...props}
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill={color} />
  </svg>
);

BlogMarkIcon.defaultProps = {
  width: 45,
  height: 45,
  color: "#E62B1E"
};

export default BlogMarkIcon;
