import React from "react";
import { Link } from "gatsby";

import { css } from "@emotion/core";
import tw from "tailwind.macro";
import styled from "@emotion/styled";
import { md } from "../styles/breakpoints";

import { Inter1, Inter2 } from "../styles/blog";
import { blogMd } from "../styles/breakpoints";
import { watermelon, white } from "../styles/colors";

export const styles = {
  btn: css`
    ${tw`no-underline inline-block px-10 py-6 text-white rounded bg-watermelon-red font-bold text-center text-lg`};
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  `,
  btnBlog: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${white};
    background-color: ${watermelon};
    padding: 16px 32px;
    border-radius: 5px;
    text-decoration: none;
    ${Inter1};
  `,
  link: tw`text-watermelon-red uppercase no-underline`,
  linkBlog: css`
    ${Inter1};
    color: ${watermelon};
    text-decoration: none;
    text-transform: uppercase;

    @media (${blogMd}) {
      ${Inter2};
    }
  `,
  invert: tw`bg-white text-watermelon-red`,
  centerbox: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

    @media (${md}) {
      flex-direction: row;
    }
  `,
};

const S = {
  Btn: styled.a`
    ${({ variant }) => styles[variant] || styles.btn};
    ${({ invert }) => invert && styles.invert};
  `,
};

const BtnBase = ({ target, children, label, to, ...props }) => {
  const Button = to ? S.Btn.withComponent(Link) : S.Btn;

  return (
    <Button
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}
      to={to}
      {...props}
    >
      {children || label}
    </Button>
  );
};

BtnBase.defaultProps = {
  label: "Learn More",
  variant: "button",
};

export const CenterBox = ({ children, className }) => (
  <div css={styles.centerbox} className={className}>
    {children}
  </div>
);

const CenterBtn = (props) => (
  <CenterBox>
    <BtnBase {...props} />
  </CenterBox>
);

const Btn = ({ center, ...props }) =>
  center ? <CenterBtn {...props} /> : <BtnBase {...props} />;

export default Btn;
