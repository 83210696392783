import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import { useWindowScroll } from "react-use";

import { BlogMarkIcon } from "../components/icons";

// import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/core";

import { blogLg, blogMd } from "../styles/breakpoints";
import { animated } from "../styles/animations";

export const blogMarkId = "blog-mark";

const fadeOutUpMark = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
`;

const fadeInUpMark = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50%, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const blogStyles = {
  mark: css`
    display: block;
    width: 45px;
    height: 45px;
    margin: 80px auto 24px;

    @media (${blogLg}) {
      margin-top: 104px;
      margin-bottom: 40px;
    }
  `,
  markHidden: css`
    @media (${blogMd}) {
      ${animated};
      animation-name: ${fadeOutUpMark};
    }
  `
};

export const headerStyles = {
  mark: css`
    display: none;

    @media (${blogMd}) {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  `,
  markHidden: css`
    opacity: 0;
    transform: translate3d(-50%, 50%, 0);
  `,
  markVisible: css`
    @media (${blogMd}) {
      ${animated};
      animation-name: ${fadeInUpMark};
    }
  `
};

const useNavbarOverlap = () => {
  const [overlapping, setOverlapping] = useState(false);
  const { y } = useWindowScroll();

  useEffect(() => {
    const mark = document.getElementById(blogMarkId);
    if (!mark) {
      return;
    }

    const { top } = mark.getBoundingClientRect();

    setOverlapping(top < 55);
  }, [y]);

  return overlapping;
};

const BlogMark = ({ id, animated, startHidden, styles, link, ...props }) => {
  const overlapping = useNavbarOverlap();
  const visible = startHidden ? overlapping : !overlapping;
  const style = animated
    ? [styles.mark, visible ? styles.markVisible : styles.markHidden]
    : styles.mark;

  return (
    <div id={id}>
      {link && (
        <Link to={link} css={style}>
          <BlogMarkIcon {...props} />
        </Link>
      )}
      {!link && <BlogMarkIcon css={style} {...props} />}
    </div>
  );
};

BlogMark.defaultProps = {
  id: blogMarkId,
  animated: true,
  startHidden: false,
  styles: blogStyles
};

export const BlogHeaderMark = () => (
  <BlogMark id={""} link={"/blog"} styles={headerStyles} startHidden={true} />
);

export default BlogMark;
