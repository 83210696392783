import { css } from "@emotion/core";
import { blogLg } from "./breakpoints";

export const depth1 = css`
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
`;

export const depth2 = css`
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
`;

export const depth3 = css`
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.13);
`;

export const scaleDeblur = css`
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
`;

export const noSelect = css`
  -webkit-touch-callout: none;
  user-select: none;
`;

export const shade1 = css`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const rem = (px) => `${px / 16}rem`;

export const gutters = css`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;

  @media (${blogLg}) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;
