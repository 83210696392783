import React from "react";
import { Link } from "gatsby";

const NavLink = ({ href, url, label, external, ...props }) => (
  <>
    {!external && (
      <Link {...props} to={url}>
        {label}
      </Link>
    )}
    {external && (
      <a {...props} href={url} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    )}
  </>
);

export default NavLink;
