import React from "react";
import SVGIcon from "./SVGIcon";

const TwitterIcon = ({ color, bg, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={bg}
      fillRule="evenodd"
      d="M19 38c10.493 0 19-8.507 19-19S29.493 0 19 0 0 8.507 0 19s8.507 19 19 19z"
      clipRule="evenodd"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M28.5 14.318c-.63.279-1.304.468-2.014.555a3.524 3.524 0 001.543-1.945 6.968 6.968 0 01-2.228.853 3.502 3.502 0 00-2.562-1.114 3.511 3.511 0 00-3.508 3.516c0 .275.03.544.09.802a9.938 9.938 0 01-7.232-3.676 3.52 3.52 0 001.09 4.695 3.423 3.423 0 01-1.594-.439v.044a3.515 3.515 0 002.816 3.447c-.294.08-.605.123-.924.123a3.3 3.3 0 01-.66-.065 3.515 3.515 0 003.28 2.442A7.028 7.028 0 0111.4 25.01a9.797 9.797 0 005.37 1.589c6.462 0 9.991-5.36 9.991-10.007 0-.153-.004-.305-.01-.454a7.15 7.15 0 001.75-1.821z"
      clipRule="evenodd"
    ></path>
  </SVGIcon>
);

TwitterIcon.defaultProps = {
  color: "#51677B",
  bg: "#DAE1E7",
  width: "38",
  height: "38",
  viewBox: "0 0 38 38"
};

export default TwitterIcon;
