import posed from "react-pose";

export const PoseButton = posed.div({
  hoverable: true,
  init: {
    scale: 1
  },
  hover: {
    scale: 1.04
  }
});

export const PoseMenu = posed.div({
  enter: {
    x: "0%",
    transition: {
      ease: [0.23, 1, 0.32, 1]
    }
  },
  exit: {
    x: "100%",
    transition: {
      ease: [0.23, 1, 0.32, 1]
    }
  }
});

export const PoseSmoke = posed.div({
  enter: {
    opacity: 0.7,
    transition: {
      ease: [0.23, 1, 0.32, 1]
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ease: [0.23, 1, 0.32, 1]
    }
  }
});
